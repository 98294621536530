import { CalendarOutlined, DeleteOutlined, EditOutlined, LinkOutlined, MenuOutlined } from '@ant-design/icons'
import { isSome } from '@salescore/buff-common'
import { Button, List, Select } from 'antd'
import { t } from 'i18next'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import { useRedirect } from '../../hooks/useRedirect'

interface Item {
  value: string
  label: string | JSX.Element
  key: string
  description?: string
  options?: Array<{ value: string; label: string }>
  optionValues?: string[]
  path?: string
}

export interface SortableListOptions {
  deletable?: boolean
  uneditable?: (name: string) => boolean
  undeletable?: string[] // TODO: 型が微妙
  bordered?: boolean
  onClick?: (item: Item) => void
  onEdit?: (item: Item) => void
}

type OnOptionChange = (argument: { item: Item; value: string[] }) => void

interface SortableElementActions {
  onDestroy?: (item: Item) => void
  onEdit?: (item: Item) => void
  onOptionChange?: OnOptionChange
}

type ItemArgument = {
  item: Item
  options?: SortableListOptions
  isActive: boolean
} & SortableElementActions
const SortableItem = SortableElement<ItemArgument>(
  // eslint-disable-next-line complexity
  ({ item, options, isActive, onDestroy, onEdit, onOptionChange }: ItemArgument) => {
    const editable = options?.uneditable === undefined ? true : !options.uneditable(item.value)
    const deletable = !(options?.undeletable ?? []).includes(item.value) && (options?.deletable ?? true)
    const onClick = options?.onClick
    const redirect = useRedirect()

    return (
      <List.Item
        className={`h-12 ${isActive ? `text-blue-500` : ``}`}
        actions={[
          // 集計軸での利用用途に特化している
          onOptionChange !== undefined && (item.options ?? []).isPresent() ? (
            <div>
              <CalendarOutlined />
              <Select
                size="small"
                style={{ width: 75, fontSize: 11 }}
                bordered={false}
                placeholder={t(`表示形式`)}
                tagRender={(option) => <span style={{ marginRight: 1 }}>{option.label}</span>}
                mode="multiple"
                value={item.optionValues ?? []}
                options={item.options}
                onChange={(value: string[]) => {
                  onOptionChange({ item, value })
                }}
              />
            </div>
          ) : undefined,
          editable && onEdit !== undefined ? (
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                onEdit(item)
              }}
              type="text"
            />
          ) : undefined,
          deletable && onDestroy !== undefined ? (
            <Button
              icon={<DeleteOutlined />}
              // danger
              onClick={() => {
                onDestroy(item)
              }}
              type="text"
            />
          ) : undefined,
        ].compact()}
      >
        {isSome(onClick) ? (
          <>
            <MenuOutlined className="pr-3" />
            <Button
              className={`w-full cursor-pointer hover:opacity-75`}
              onClick={() => {
                onClick(item)
              }}
              style={{ textAlign: 'left', display: 'inline-block', overflow: 'hidden', textOverflow: 'ellipsis' }}
              type="text"
            >
              <span className={isActive ? `text-blue-500` : ``} style={{ display: 'contents' }}>
                {item.label}
              </span>
            </Button>
          </>
        ) : (
          // <Space className="hover:opacity-75">
          <>
            <MenuOutlined className="pr-3 hover:opacity-75" />
            <span className="w-full text-left hover:opacity-75">{item.label}</span>
            {/* </Space> */}
          </>
        )}
        {item.path !== undefined && (
          <Button
            type="text"
            icon={<LinkOutlined />}
            onClick={() => {
              redirect(item.path!)
            }}
          />
        )}
      </List.Item>
    )
  },
)

type Argument = {
  items: Item[]
  dragging: boolean
  className?: string
  options?: SortableListOptions
  value?: string
  loading: boolean
  axis?: 'x' | 'y'
  lockAxis?: 'x' | 'y'
  lockToContainerEdges?: boolean
  lockOffset?: string
} & SortableElementActions
export const SortableList = SortableContainer<Argument>(
  ({ items, dragging, className, options, value, loading, onDestroy, onEdit, onOptionChange }: Argument) => (
    <List
      loading={loading}
      bordered={options?.bordered ?? true}
      className={`${className ?? ''} overflow-auto`}
      // overflow: 'clip'を指定することでドラッグ時にスクロールが機能するようになる
      style={dragging ? { cursor: 'grabbing' } : { cursor: 'grab', overflow: 'clip' }}
      dataSource={items}
      renderItem={(item, index) => (
        <SortableItem
          key={`item-${item.key}`}
          index={index}
          item={item}
          onDestroy={onDestroy}
          onEdit={onEdit}
          onOptionChange={onOptionChange}
          options={options}
          isActive={value === item.value}
        />
      )}
    />
  ),
)
