import * as React from 'react'

const noop = () => {
  // empty
}

const { useState } = React

type Argument = ((state: boolean) => React.ReactElement) | React.ReactElement
export const useHover = (callback: Argument): React.ReactElement => {
  const [state, setState] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onMouseEnter = (originalOnMouseEnter?: any) => (event: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    ;(originalOnMouseEnter ?? noop)(event)
    setState(true)
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onMouseLeave = (originalOnMouseLeave?: any) => (event: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    ;(originalOnMouseLeave ?? noop)(event)
    setState(false)
  }

  if (typeof callback === 'function') {
    callback = callback(state)
  }

  const element = React.cloneElement(callback, {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    onMouseEnter: onMouseEnter(callback.props.onMouseEnter),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    onMouseLeave: onMouseLeave(callback.props.onMouseLeave),
  })

  return element
}
