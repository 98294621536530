import { CORE_CONSTANT } from '../../../../constant'
import type {
  ViewQueryAggregation,
  ViewQueryDimensionFieldForMultiTablePivot,
  ViewQueryMultiTablePivot,
} from '../../../../schemas/query'
import type { ViewUiKpi, ViewUiKpiPivotColumn } from '../../../../schemas/ui/ui'
import type { ViewConfigKpi, ViewConfigPivot, ViewConfigSheet } from '../../../../schemas/view_config'
import type { CompileContext } from '../../common'
import type { CompileViewConfigResponse } from '../../response'
import { compileGoalConfig } from '../kpiPivot/compileGoalConfig'
import { compileSheetAndMeasure, type CompileSheetAndMeasureResultExtra } from '../sheet/compileSheetAndMeasure'
import { generateDimensionFieldName } from '../sheet/measure/generateDimensionField'
import { addDateFieldAndFilter } from './addDateFieldAndFilter'
import { addSalescoreUserField } from './addSalescoreUserFields'
import { addSalescoreUserTree } from './addSalescoreUserTree'
import { generateNodeFromDimension } from './generateNodeFromDimension'
import { generateUiDimensionCandidates } from './generateUiDimensionCandidates'
import { updateConfigByAdditionalConfig } from './updateConfigByAdditionalConfig'

export function compileKpiViewConfig(config: ViewConfigKpi, context: CompileContext): CompileViewConfigResponse {
  const pivot: ViewConfigPivot = context.additionalConfig?.kpiParameter?.pivot ?? {
    rows: [],
    columns: [],
  }
  const compiled = compileKpiViewConfigWithoutGoal(config, pivot, context)

  const goalConfigQueries = compileGoalConfig({
    goalConfig: context.additionalConfig?.kpiParameter?.goalConfig,
    pivot,
    context,
    kpiIds: [context.resources.view.id],
  })

  const sortKeys = context.additionalConfig?.kpiParameter?.sorter?.columnKeys ?? []
  const query: ViewQueryMultiTablePivot = {
    type: 'multiTablePivot',
    tree: sortKeys.isPresent()
      ? generateNodeFromDimension({ rows: [], columns: [] }, 0, [])
      : generateNodeFromDimension(pivot, 0, []),
    queries: [
      ...[compiled].compact().map((compiled) => ({
        // kpiIdとkpiNameは、実質view.idとview.nameである。この文脈でコンパイルするときは、viewの情報がないため、指定できない
        // かつ、この文脈で使うときは指定する必要性も特にないはずなので、一旦ダミーの値を入れる
        kpiId: context.resources.view.id,
        kpiName: context.resources.view.name,
        kpiGroupName: context.resources.view.name, // 使われないはず
        name: `actual`, // TODO: もはやこの辺が機能していないかも
        role: `actual`,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        schema: compiled.query ?? null,
      })),
      ...goalConfigQueries,
    ],
    pivot: {
      rows: pivot.rows.map(
        (dimension): ViewQueryDimensionFieldForMultiTablePivot => ({
          name: generateDimensionFieldName(dimension),
          sortedValues: dimension.sortedValues,
        }),
      ),
      columns: pivot.columns.map(
        (dimension): ViewQueryDimensionFieldForMultiTablePivot => ({
          name: generateDimensionFieldName(dimension),
          sortedValues: dimension.sortedValues,
        }),
      ),
    },
    sorter: context.additionalConfig?.kpiParameter?.sorter,
  }

  const ui: ViewUiKpi = {
    type: 'KpiSheet',
    dimensions: (compiled?.ui.dimensions ?? []).uniqueBy((x) => x.key),
    kpi: {
      id: `kpi`, // TODO
      label: `KPI`, // TODO
      withDateField: config.date !== undefined,
      ...config.ui?.kpi,
    },
    // 基本的にcompile側で存在するはずなので、以下は実行されないはず
    pivot: compiled?.ui.pivot ?? {
      rows: pivot.rows.map(
        (dimension): ViewUiKpiPivotColumn => ({
          key: dimension.key,
          label: dimension.label, // TODO
          fieldType: dimension.key === CORE_CONSTANT.KPI_PIVOT_USER_DIMENSION().key ? 'user' : 'string',
        }),
      ),
      columns: pivot.columns.map(
        (dimension): ViewUiKpiPivotColumn => ({
          key: dimension.key,
          label: dimension.label, // TODO
          fieldType: dimension.key === CORE_CONSTANT.KPI_PIVOT_USER_DIMENSION().key ? 'user' : 'string',
        }),
      ),
    },
    appearance: config.ui?.appearance,
  }

  return {
    ui: [ui],
    query,
    queries: [query],
    context,
  }
}

export function compileKpiViewConfigWithoutGoal(
  config: ViewConfigKpi,
  pivot: ViewConfigPivot | undefined,
  context: CompileContext,
):
  | {
      query: ViewQueryAggregation
      ui: Pick<ViewUiKpi, 'pivot' | 'dimensions'>
      extra: CompileSheetAndMeasureResultExtra
    }
  | undefined {
  const { logs } = context
  if (config.sheet === undefined || config.measure === undefined) {
    logs.warn(`KPI設定が完了していません`)
    return undefined
  }
  const configRaw = context.viewSearcher.searchConfigIfReference(config.sheet)
  if (configRaw === undefined) {
    logs.error(`ビューが見つかりません。viewId: ${config.sheet.type}`)
    return undefined
  }
  if (configRaw.type !== 'sheet') {
    logs.error(`シートではありません。type: ${configRaw.type}`)
    return undefined
  }

  //
  // sheet設定に対して、additionalConfig,user,dateの適用などを行う
  // ・additionalConfig : フィルタの追加など（ダッシュボードからドリルダウンしたとき）
  // ・user : ユーザー項目のため、salescore_usersテーブルの紐付け、フィールド追加
  // ・date : 日付項目のため、日付フィールドと日付フィルタを追加（フィールドはdimensionとして使われる）
  //
  const sheetConfig: ViewConfigSheet = updateConfigByAdditionalConfig(configRaw, context)
  const sheetConfigWithDate = addDateFieldAndFilter(sheetConfig, config.date)
  const sheetConfigWithUserTree = addSalescoreUserTree(sheetConfigWithDate, config.user, context)
  const sheetConfigWithUser = addSalescoreUserField(sheetConfigWithUserTree, config.user, context)
  const prunedSheetConfig = pruneMeasure(sheetConfigWithUser, ``) // このロジックは過去に必要だったものであり、今は不要なはずだが、デグレが怖いのと影響範囲の調査をサボって残し続けている。 TODO: 削除

  // sheetに対してmeasureとpivotを渡して、集計用のqueryとuiを得る
  const result = compileSheetAndMeasure(
    config.measure,
    prunedSheetConfig,
    pivot,
    [
      ...(config.dimensionAliases ?? []),
      config.date === undefined
        ? undefined
        : {
            alias: CORE_CONSTANT.KPI_PIVOT_KPI_DATE_DIMENSION().key, // TODO: 各所でこういうdimensionAliasの処理をしている？
            property: config.date.property,
          },
    ].compact(),
    context,
  )
  // kpiの文脈のcompileでは、必ず1つ以下のqueryが生成されるはず
  if (result === undefined) {
    return undefined
  }

  const dimensions = generateUiDimensionCandidates(sheetConfig, config, context)
  return {
    query: result.query,
    ui: {
      ...result.ui,
      dimensions,
    },
    extra: result.extra,
  }
}

// 指定されたKPI用のmeasureのみを残して削除する
function pruneMeasure(config: ViewConfigSheet, measureName: string): ViewConfigSheet {
  return {
    ...config,
    fields: (config.fields ?? []).map((field) => ({
      ...field,
      measures: field.measures
        ?.filter((x) => x.name === measureName)
        .map((x) => ({
          ...x,
          name: CORE_CONSTANT.KPI_PIVOT_VALUE_COLUMN_NAME,
        })),
    })),
  }
}
