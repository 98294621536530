import type { Field, Limit, Offset, OrderBy, SelectCommand } from '../parser/grammer_schema'
import { aggregate } from './aggregate'
import { expression, expressionFieldName } from './expression'
import { fromAndJoin } from './fromAndJoin'
import type { ResultRecord, Table, TemporaryTable } from './types'

export function selectCommand(c: SelectCommand, tables: Table[]): ResultRecord[] {
  const t1 = fromAndJoin(c.from, c.joins ?? [], tables)
  const t2 = where(c.where, t1)
  const t3 = orderBy(c.orderBy, c.fields, t2)
  const r1 = selectOrAggregateFields(c, t3)
  const r2 = offset(c.offset, r1)
  const r3 = limit(c.limit, r2)
  return r3
}

export function where(where: SelectCommand['where'], temporaryTable: TemporaryTable): TemporaryTable {
  if (where === null) {
    return temporaryTable
  }

  return {
    ...temporaryTable,
    records: temporaryTable.records.filter(
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      (record) => expression(where.expression, record, { fields: [] }), // where文ではfieldのasは解決されない
    ),
  }
}

function orderBy(orderBy: OrderBy | null, fields: Field[], temporaryTable: TemporaryTable): TemporaryTable {
  if (orderBy === null) {
    return temporaryTable
  }
  // TODO: まともにasc,descの実装、比較関数の実装
  const records = temporaryTable.records.sortBy((record) => {
    const values = orderBy.orders.map((order) =>
      // TODO: expressionがfieldのaliasの可能性があるので、その際は別途解決が必要
      expression(order.expression, record, { fields }),
    )
    return values
  })
  return {
    ...temporaryTable,
    records: orderBy.orders.first()!.order === 'desc' ? records.reverse() : records,
  }
}

function offset(offset: Offset | null, rows: ResultRecord[]) {
  if (offset === null) {
    return rows
  }
  return rows.slice(offset.start)
}

function limit(limit: Limit | null, rows: ResultRecord[]) {
  if (limit === null) {
    return rows
  }
  return rows.slice(0, limit.count)
}

function selectOrAggregateFields(command: SelectCommand, temporaryTable: TemporaryTable) {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (command.groupBy !== null || command.fields.some((x) => x.expression.type === 'aggregateFunction')) {
    return aggregate(command.groupBy, command.fields, temporaryTable)
  }
  return selectFields(command.fields, temporaryTable)
}

function selectFields(fields: Field[], temporaryTable: TemporaryTable): ResultRecord[] {
  return temporaryTable.records.map((record): ResultRecord => {
    const resultRecord = fields.map((field) => {
      if (field.expression.type === 'aggregateFunction') {
        throw new Error(`not implmented`)
      }
      const value = expression(field.expression, record, { fields })

      return [field.as ?? expressionFieldName(field.expression), value] as [string, unknown]
    })

    return resultRecord.toObject(([key, value]) => [key, value])
  })
}
