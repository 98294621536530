import { DndContext, type DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import type { Transform } from '@dnd-kit/utilities'
import { type ReactNode, useEffect, useState } from 'react'

import { SortableItem, type SortableItemProperties } from './SortableItem'

interface SortableContainerProperties {
  items: SortableItemProperties[]
  onSortEnd: (ids: string[]) => void
  direction?: 'horizontal' | 'vertical'
  transformStyle?: Partial<Transform>
}

export function SortableContainer(properties: SortableContainerProperties): ReactNode {
  const [ids, setIds] = useState(properties.items.map((index) => index.id))
  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 1 } }))
  const strategy = properties.direction === 'horizontal' ? horizontalListSortingStrategy : verticalListSortingStrategy

  function onDragEnd(event: DragEndEvent): void {
    const { active, over } = event
    const activeId = active.id
    const overId = over?.id

    if (typeof activeId === 'string' && typeof overId === 'string' && activeId !== overId) {
      const oldIndex = ids.indexOf(activeId)
      const newIndex = ids.indexOf(overId)
      const next = arrayMove(ids, oldIndex, newIndex)
      properties.onSortEnd(next)
      setIds(next)
    }
  }

  useEffect(() => {
    setIds(() => properties.items.map((index) => index.id))
  }, [properties.items])

  return (
    <DndContext sensors={sensors} onDragEnd={onDragEnd}>
      <SortableContext items={ids} strategy={strategy}>
        <div className={properties.direction === 'horizontal' ? 'flex' : ''}>
          {ids
            .map((id) => {
              const item = properties.items.find((index) => index.id === id)
              return item === undefined ? undefined : (
                <SortableItem key={id} id={id} render={item.render} transformStyle={properties.transformStyle} />
              )
            })
            .compact()}
        </div>
      </SortableContext>
    </DndContext>
  )
}
