// eslint-disable-next-line @eslint-community/eslint-comments/disable-enable-pair
/* eslint-disable max-lines */
import { dateUtil, isNull, isPresent, isSome, isTruthy, type MRecord } from '@salescore/buff-common'
import { CORE_CONSTANT, type ViewKpiAppearance } from '@salescore/core'
import { Empty } from 'antd'
import type { EChartsOption, LegendComponentOption, SeriesOption } from 'echarts'
import { t } from 'i18next'
import { z } from 'zod'

import {
  calculateUnit,
  numberWithDelimiterFilter,
  numberWithFixedDecimal,
  unitLabel as getUnitLabel,
} from '../../misc/filters'
import {
  type ChartLabelDisplayModeValue,
  type ChartLegendsDisplayModeValue,
  EChart,
  type EChartProperties,
} from './EChart'

export type StackedChartType = 'bar' | 'line' | 'area'

const xAxisLabelDict = {
  auto: {
    show: true,
  },
  none: {
    show: false,
  },
  showAll: {
    show: true,
    interval: 0,
    rotate: 30,
  },
}

interface XAxisItem {
  type: string
  data: string[]
  axisLabel: (typeof xAxisLabelDict)[keyof typeof xAxisLabelDict]
}

const seriesItemDataSchema = z.object({
  value: z.number(),
  key: z.string(),
  groupKey: z.string().optional(),
})
type SeriesItemData = z.infer<typeof seriesItemDataSchema>

interface SeriesItem {
  name: string
  data: SeriesItemData[]
}

type EChartStackedProperties = Omit<EChartProperties, 'option'> & {
  options: GenerateStackedEChartOptionOptions[]
  height?: number
}

const parameterSchema = z.object({
  name: z.string(),
  value: z.number(),
  color: z.string(),
  data: seriesItemDataSchema,
})
type Parameter = z.infer<typeof parameterSchema>

export const EChartStacked = (properties: EChartStackedProperties) => {
  const options = properties.options.map((element) => generateStackedEChartOption(element)).compact()
  const [option] = options

  if (isNull(option)) {
    return (
      <div className="flex h-full items-center justify-center">
        <Empty />
      </div>
    )
  }

  if (options.length === 1) {
    return <EChart {...properties} option={option} />
  }

  // NOTE: options が複数の場合は、xAxis, series を merge し EChart に渡す
  //       series[n0].data[n1].key と xAxis.data[n1] は同じ値を取ることを前提とする
  const [xAxis] = Array.isArray(option.xAxis) ? option.xAxis : []
  const mergedXAxis =
    xAxis === undefined
      ? undefined
      : {
          ...xAxis,
          data: options
            // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
            .flatMap((v) => (v.xAxis as [XAxisItem])[0].data)
            .unique()
            .sort(),
        }

  const mergedSeries = options
    .flatMap((v) => v.series)
    .compact()
    .map((v) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
      const seriesItem = v as SeriesItem
      return {
        ...seriesItem,
        data: mergedXAxis?.data.map((xKey) => {
          const data = seriesItem.data.find((d) => d.key === xKey)
          if (data?.value === undefined) return null
          if (seriesItem.name === CORE_CONSTANT.KPI_PIVOT_TOTAL_STRING) {
            return data
          }
          return data.value === 0 ? null : data
        }),
      }
    })

  const mergedLegend = {
    // eslint-disable-next-line @typescript-eslint/no-misused-spread
    ...option.legend,
    data: options.flatMap((v) => {
      if (Array.isArray(v.legend) || isNull(v.legend?.data)) {
        return []
      }
      return v.legend.data
    }),
  }

  return (
    <EChart
      {...properties}
      option={{
        ...option,
        xAxis: mergedXAxis,
        series: mergedSeries,
        legend: mergedLegend,
      }}
    />
  )
}

type ChartRecord = Record<string, unknown>

interface GenerateStackedEChartOptionOptions {
  chartType: StackedChartType
  records: ChartRecord[]
  xKey: string
  stackGroups: Array<{ valueKey: string; stackKey: string }>
  groupKey?: string
  xLabelKey?: string
  groupLabelKey?: string
  fillKeysAsDate?: boolean
  valueLabelDisplayMode?: ChartLabelDisplayModeValue
  valueLabelUnitType?: ViewKpiAppearance['unitType']
  valueLabelDecimalPlaceType?: ViewKpiAppearance['decimalPlaceType']
  valueLabelShouldShowPercentage?: boolean
  axisLabelDisplayMode?: ChartLabelDisplayModeValue
  legendsDisplayMode?: ChartLegendsDisplayModeValue
  seriesName?: (groupLabel: string, groupKey: string) => string
  emphasis?: {
    focus: 'none' | 'self' | 'series'
  }
  connectNulls?: boolean
  displayTotal?: boolean
}

function generateStackedEChartOption({
  chartType,
  records,
  xKey,
  stackGroups,
  groupKey,
  xLabelKey,
  groupLabelKey,
  fillKeysAsDate,
  valueLabelDisplayMode,
  valueLabelUnitType,
  valueLabelDecimalPlaceType,
  valueLabelShouldShowPercentage,
  axisLabelDisplayMode,
  legendsDisplayMode,
  seriesName,
  emphasis,
  connectNulls,
  displayTotal,
}: GenerateStackedEChartOptionOptions) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion,@typescript-eslint/no-unnecessary-condition
  const grouped = records.groupBy((x) => (groupKey === undefined ? '' : ((x[groupKey] as string) ?? '')))
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  const xGrouped = records.groupBy((x) => x[xKey] as string)
  const xKeysRaw = xGrouped
    .keys()
    .unique()
    .sortBy((x) => x)
  const xKeys = fillKeysAsDate === true ? fillDateKeys(xKeysRaw) : xKeysRaw
  const xKeyToValue =
    xLabelKey === undefined
      ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        ({} as Record<string, string>)
      : xGrouped.transformValues((vs) => vs.first()![xLabelKey]).data
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion,@typescript-eslint/no-unnecessary-condition
  const xLabels = xKeys.map((key) => (xKeyToValue[key] as string) ?? key)

  if (records.isEmpty()) {
    return
  }

  // const xKeyToSum = xGrouped.transformValues((records) =>
  //   records
  //     .map((record) => {
  //       return stackGroups.map((stack) => record[stack.valueKey] as number).sum()
  //     })
  //     .sum(),
  // ).data

  const series: SeriesOption[] = stackGroups.flatMap((stackGroup) => {
    const commonSeriesProperties = {
      type: chartType === 'area' ? 'line' : chartType,
      areaStyle: chartType === 'area' ? {} : undefined,
      showAllSymbol: true,
      symbolSize: 8,
      emphasis: emphasis ?? {
        focus: 'series' as const,
      },
      stack: chartType === 'bar' ? stackGroup.stackKey : undefined, // 棒グラフ以外はstackしない
      connectNulls: chartType === 'line' ? connectNulls : undefined,
    }

    const stackSum = xGrouped.transformValues((records) =>
      records
        // TOTALの値は集計から外す
        .filter((record) => isNull(groupKey) || record[groupKey] !== CORE_CONSTANT.KPI_PIVOT_TOTAL_STRING)
        .map((record) => record[stackGroup.valueKey])
        .sum(),
    ).data

    const actualSeries: SeriesOption[] = grouped

      .map((groupKey, rs) => {
        if (groupKey === CORE_CONSTANT.KPI_PIVOT_TOTAL_STRING) {
          return
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
        const value = rs.groupByUniqueKey((r) => r[xKey] as string)
        const r = rs[0]
        const groupLabel =
          // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion,@typescript-eslint/no-unnecessary-condition
          r !== undefined && groupLabelKey !== undefined ? ((r[groupLabelKey] as string) ?? groupKey) : groupKey
        return {
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          name: seriesName
            ? seriesName(groupLabel, stackGroup.stackKey)
            : [groupLabel, stackGroup.stackKey].filter((x) => isPresent(x)).join(' - '),
          ...commonSeriesProperties,
          label: {
            show: valueLabelDisplayMode !== 'none',
            fontSize: 10,
            // position: `top`,
            formatter: (p: unknown): string => {
              const parsedParameter = parameterSchema.safeParse(p)
              if (isNull(parsedParameter.data)) {
                return ''
              }
              const parameter = parsedParameter.data
              const { value } = parameter
              const sum = stackSum[parameter.data.key]

              return generateDisplayValue({
                chartType,
                value,
                sum,
                shouldIgnoreSmallValue: true,
                valueLabelDisplayMode,
                valueLabelUnitType,
                valueLabelDecimalPlaceType,
                valueLabelShouldShowPercentage,
              })
            },
          },
          tooltip: {
            formatter: (p: unknown) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
              const parameter = p as {
                seriesName: string
                name: string
                value: number | string
                color: string
                data: SeriesItemData
              }
              const sum = stackSum[parameter.data.key]
              const displayValue = generateDisplayValue({
                chartType,
                value: parameter.value,
                sum,
                valueLabelDisplayMode,
                valueLabelUnitType,
                valueLabelDecimalPlaceType,
                valueLabelShouldShowPercentage,
              })

              if (!isPresent(displayValue)) {
                return ''
              }

              return generateTooltip({
                seriesName: parameter.seriesName,
                name: parameter.name,
                displayValue,
                color: parameter.color,
              })
            },
          },
          data: xKeys.map((xKey): SeriesItemData => {
            const x = value[xKey]
            if (x === undefined) {
              return {
                value: 0,
                key: xKey,
              }
            }
            return {
              value: Number(x[stackGroup.valueKey]),
              key: xKey,
              groupKey,
            }
          }),
        }
      })
      .compact()

    const totalSeries: SeriesOption = {
      name: CORE_CONSTANT.KPI_PIVOT_TOTAL_STRING,
      ...commonSeriesProperties,
      itemStyle: {
        color: '#6b7280',
      },
      label: {
        show: valueLabelDisplayMode !== 'none',
        fontSize: 10,
        position: 'top',
        formatter: (p: unknown): string => {
          const parsedParameter = parameterSchema.safeParse(p)
          if (isNull(parsedParameter.data)) {
            return ''
          }
          const parameter = parsedParameter.data
          const value = getTotalValue({ parameter, grouped, xKey, groupKey, valueKey: stackGroup.valueKey })

          return generateDisplayValue({
            chartType,
            value,
            shouldIgnoreSmallValue: true,
            valueLabelDisplayMode,
            valueLabelUnitType,
            valueLabelDecimalPlaceType,
            valueLabelShouldShowPercentage,
          })
        },
      },
      tooltip: {
        formatter: (p: unknown) => {
          const parsedParameter = parameterSchema.safeParse(p)
          if (isNull(parsedParameter.data)) {
            return ''
          }
          const parameter = parsedParameter.data
          const value = getTotalValue({ parameter, grouped, xKey, groupKey, valueKey: stackGroup.valueKey })
          const displayValue = generateDisplayValue({
            chartType,
            value,
            valueLabelDisplayMode,
            valueLabelUnitType,
            valueLabelDecimalPlaceType,
            valueLabelShouldShowPercentage,
          })

          if (!isPresent(displayValue)) {
            return ''
          }

          return generateTooltip({
            seriesName: t('集計値'),
            name: parameter.name,
            displayValue,
            color: parameter.color,
          })
        },
      },
      data: xKeys.map(
        (xKey): SeriesItemData => ({
          value: 0,
          key: xKey,
        }),
      ),
    }

    return [...actualSeries, chartType === 'bar' && isTruthy(displayTotal) ? totalSeries : undefined].compact()
  })

  const option: EChartsOption = {
    legend: generateLegendOption({ series, legendsDisplayMode }),
    tooltip: {
      // trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: xLabels,
        triggerEvent: true,
        axisLabel: xAxisLabelDict[axisLabelDisplayMode ?? 'auto'],
      },
    ],
    yAxis: [
      {
        type: 'value',
      },
    ],
    series,
  }

  return option
}

function generateLegendOption({
  series,
  legendsDisplayMode,
}: {
  series: SeriesOption[]
  legendsDisplayMode?: ChartLegendsDisplayModeValue
}): LegendComponentOption | undefined {
  const filteredLegendOption: LegendComponentOption = {
    type: 'scroll',
    icon: 'roundRect',
    data: series
      .filter((s) => isPresent(s.name) && s.name !== CORE_CONSTANT.KPI_PIVOT_TOTAL_STRING)
      .map((s) => `${s.name}`)
      .compact(),
  }

  switch (legendsDisplayMode) {
    case 'none': {
      return undefined
    }
    case 'show': {
      return filteredLegendOption
    }
    case 'auto':
    case undefined: {
      // seriesが多すぎるとレイアウトが崩れるので、10より多ければ非表示
      return series.length > 10 ? undefined : filteredLegendOption
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const x: never = legendsDisplayMode
      return undefined
    }
  }
}

function fillDateKeys(sortedKeys: string[]): string[] {
  const first = sortedKeys.first()
  const last = sortedKeys.last()
  if (first === undefined || last === undefined) {
    return sortedKeys
  }

  const a = dateUtil.detect(first)
  const b = dateUtil.detect(last)
  if (a === undefined || b === undefined) {
    return sortedKeys
  }
  if (a.timeframe !== b.timeframe) {
    return sortedKeys
  }

  if (a.timeframe === 'fiscal_quarter' || b.timeframe === 'fiscal_quarter') {
    return dateUtil.quarterRange(first, last)
  }
  if (!('date' in a) || !('date' in b)) {
    // ありえないはずだが、一応
    return sortedKeys
  }

  return dateUtil.range(a.date, b.date, { filterBy: a.timeframe }).map((x) => x.format(a.format))
}

function generateDisplayValue({
  chartType,
  value,
  sum,
  shouldIgnoreSmallValue,
  valueLabelDisplayMode,
  valueLabelUnitType,
  valueLabelDecimalPlaceType,
  valueLabelShouldShowPercentage,
}: {
  chartType: StackedChartType
  value: number | string
  sum?: number
  shouldIgnoreSmallValue?: boolean
  valueLabelDisplayMode?: ChartLabelDisplayModeValue
  valueLabelUnitType?: ViewKpiAppearance['unitType']
  valueLabelDecimalPlaceType?: ViewKpiAppearance['decimalPlaceType']
  valueLabelShouldShowPercentage?: boolean
}) {
  const numberValue = Number(value)

  if (Number.isNaN(numberValue)) {
    return `${value}`
  }

  if (numberValue === 0) {
    // 折れ線グラフ・エリアグラフの場合は0を表示する
    return chartType === 'bar' ? '' : '0'
  }

  const percent = isSome(sum) && sum !== 0 ? (numberValue / sum) * 100 : undefined // sumが0の時はundefinedでいいのだろうか？
  // 一定の割合に満たないものを表示すると表示がごちゃっとするので、表示しない
  if (valueLabelDisplayMode !== 'showAll' && isTruthy(shouldIgnoreSmallValue) && isSome(percent) && percent < 22) {
    return ``
  }

  // eslint-disable-next-line unicorn/no-useless-undefined
  const valueConvertedInUnit = calculateUnit(numberValue, valueLabelUnitType, undefined)
  // eslint-disable-next-line unicorn/no-useless-undefined
  const valueRoundedToDecimal = numberWithFixedDecimal(valueConvertedInUnit, valueLabelDecimalPlaceType, undefined)
  const percentRoundedToDecimal = isSome(percent)
    ? // eslint-disable-next-line unicorn/no-useless-undefined
      numberWithFixedDecimal(percent, valueLabelDecimalPlaceType, undefined)
    : undefined
  // eslint-disable-next-line unicorn/no-useless-undefined
  const unitLabel = getUnitLabel(numberValue, valueLabelUnitType, undefined)
  const displayUnitLabel = isPresent(unitLabel) ? ' ' + unitLabel : ''
  const valueWithUnit = `${numberWithDelimiterFilter(valueRoundedToDecimal)}${displayUnitLabel}`

  return isTruthy(valueLabelShouldShowPercentage) && isSome(percentRoundedToDecimal)
    ? `${valueWithUnit} (${percentRoundedToDecimal}%)`
    : valueWithUnit
}

export function generateTooltip({
  seriesName,
  name,
  displayValue,
  color,
}: {
  seriesName: string
  name: string
  displayValue: string
  color: string
}) {
  // EChartのデフォルトのtooltip要素をコピーして使っている
  return `<div style='margin: 0px 0 0;line-height:1;'>
  <div style='font-size:14px;color:#666;font-weight:400;line-height:1;'>${seriesName}</div>
  <div style='margin: 10px 0 0;line-height:1;'>
    <div style='margin: 0px 0 0;line-height:1;'>
      <span style='display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${color};'></span>
      <span style='font-size:14px;color:#666;font-weight:400;margin-left:2px'>${name}</span>
      <span style='float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900'>${displayValue}</span>
      <div style='clear:both'></div>
    </div>
    <div style='clear:both'></div>
  </div>
  <div style='clear:both'></div>
</div>`
}

function getTotalValue({
  parameter,
  grouped,
  xKey,
  groupKey,
  valueKey,
}: {
  parameter: Parameter
  grouped: MRecord<Record<string, ChartRecord[]>>
  xKey: string
  groupKey?: string
  valueKey: string
}): number {
  const firstGroup = grouped.toArray().first()
  if (isNull(firstGroup)) {
    return 0
  }
  const [, records] = firstGroup
  const totalGroupKey =
    isNull(groupKey) || isNull(records.first()?.[groupKey]) ? '' : CORE_CONSTANT.KPI_PIVOT_TOTAL_STRING
  const totalGroup = grouped.data[totalGroupKey] ?? []

  const { key } = parameter.data
  const total = totalGroup.find((x) => x[xKey] === key)
  if (isNull(total)) {
    return 0
  }
  const parsed = z.number().safeParse(total[valueKey])
  return parsed.data ?? 0
}
