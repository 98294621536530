import { isNull } from '@salescore/buff-common'

import {
  type GenerateSearchSqlForStreamArgument,
  generateSearchSqlMustache,
  orderByLength,
  SEARCH_RESULT_LABEL_COLUMN_NAME,
  SEARCH_RESULT_VALUE_COLUMN_NAME,
} from './common'

export function generateSearchSqlForZohoUserWithActive({ model }: GenerateSearchSqlForStreamArgument) {
  const nameProperty = model.properties.find((x) => x.meta === 'name')
  const labelField = nameProperty?.name ?? 'id'

  const userActiveStatusProperty = model.properties.find((x) => x.name === 'status')

  if (isNull(nameProperty) || isNull(userActiveStatusProperty)) {
    return
  }
  const searchSql = `SELECT
  id as ${SEARCH_RESULT_VALUE_COLUMN_NAME},
  "${labelField}" as ${SEARCH_RESULT_LABEL_COLUMN_NAME}
  FROM "${model.name}"
  WHERE ${generateSearchSqlMustache(`"${labelField}"`)}
  AND "${userActiveStatusProperty.name}" = 'active'
  `

  return {
    sql: orderByLength(searchSql, `"${labelField}"`),
    dependedPropertyNames: [
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      nameProperty === undefined
        ? undefined
        : {
            streamName: model.name,
            propertyName: nameProperty.name,
          },
    ].compact(),
  }
}
