import { Either, pipe } from 'effect'
import { type Dispatch, useCallback, useState } from 'react'
import type { ZodSchema } from 'zod'

import { logger } from '../misc/logtail'

function writeToLocalStorage(key: string, value: unknown): void {
  pipe(
    Either.try(() => JSON.stringify(value)),
    Either.mapBoth({
      onRight: (v) => {
        globalThis.localStorage.setItem(key, v)
      },
      onLeft: (error) => {
        logger.error('ERROR', String(error))
      },
    }),
  )
}

export function useStateWithLocalStorage<T>(
  key: string,
  defaultValue: T,
  schema: ZodSchema<T>,
): [T, Dispatch<T>, () => void] {
  const [storedValue, _setStoredValue] = useState<T>(() => {
    const item = globalThis.localStorage.getItem(key)
    if (item === null) {
      writeToLocalStorage(key, defaultValue)
      return defaultValue
    }
    return pipe(
      Either.try(() => schema.parse(JSON.parse(item))),
      Either.getOrElse(() => defaultValue),
    )
  })

  const setStoredValue = useCallback(
    (value: T) => {
      _setStoredValue(value)
      writeToLocalStorage(key, value)
    },
    [_setStoredValue, key],
  )

  const removeValue = useCallback(() => {
    globalThis.localStorage.removeItem(key)
    _setStoredValue(defaultValue)
  }, [key, defaultValue])

  return [storedValue, setStoredValue, removeValue] as const
}
