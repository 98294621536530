import type { CSSProperties } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

export interface SortableFlexElementContentItem {
  key: string
  render: () => JSX.Element
}

interface ItemArgument {
  item: SortableFlexElementContentItem
}
const SortableItem = SortableElement<ItemArgument>(
  ({ item }: ItemArgument) => item.render(),
  // TODO: withRefを使わないとCustomContainerが使えない。使い方がいまいちわからなかった。
  // { withRef: true }
)

interface Argument {
  items: SortableFlexElementContentItem[]
  dragging: boolean
  className?: string
  // CustomContainer?: React.FC<SortableFlexElementCustomContainerArg>
}
export const SortableFlexElementContent = SortableContainer<Argument>(({ items, dragging, className }: Argument) => {
  const style: CSSProperties = dragging ? { cursor: 'grabbing' } : { cursor: 'grab' }

  // if (CustomContainer !== undefined) {
  //   return <CustomContainer style={style} className={className}>
  //     {items.map((item, index) => {
  //       return <SortableItem key={`item-${item.key}`} index={index} item={item} />
  //     })}
  //   </CustomContainer>
  // }

  return (
    <div className={`sortable-flex-element-content ${className ?? ''}`} style={style}>
      {items.map((item, index) => (
        <SortableItem key={`item-${item.key}`} index={index} item={item} />
      ))}
    </div>
  )
})
