import { isNull, isSome, roundValue } from '@salescore/buff-common'
import type { CoreModel, ModelProperty, ViewConfigTreeNode, ViewKpiAppearance } from '@salescore/core'
import dayjs from 'dayjs'
import { t } from 'i18next'

export const numberWithDelimiterFilter = (x: unknown): string => {
  if (typeof x === 'string' || typeof x === 'number') {
    const string_ = x.toString()
    const [integer, decimal] = string_.split('.')
    const intWithDelimiter = integer?.replace(/(\d)(?=(\d{3})+$)/g, '$1,') ?? '0'
    return isSome(decimal) ? `${intWithDelimiter}.${decimal}` : intWithDelimiter
  }
  return ''
}
export function calculateUnit(
  x: number,
  unit: ViewKpiAppearance['unitType'],
  // 移行前のデータに対応するため残しておく
  abbreviationType: ViewKpiAppearance['abbreviationType'],
): number {
  if (isNull(unit)) {
    return calculateUnitWithAbbreviatedType(x, abbreviationType)
  }

  switch (unit) {
    case 'noUnit': {
      return x
    }
    case 'unitThousand': {
      // return Math.floor(x / 1000)
      return x / 1000
    }
    case 'unitTenThousand': {
      // return Math.floor(x / 1000 / 10)
      return x / 1000 / 10
    }
    case 'unitMillion': {
      // return Math.floor(x / 1000 / 1000)
      return x / 1000 / 1000
    }
    case 'unitHandredMillion': {
      // return Math.floor(x / 1000 / 1000 / 100)
      return x / 1000 / 1000 / 100
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const a: never = unit
      return x
    }
  }
}

export function numberWithFixedDecimal(
  number_: number,
  decimalPlaceType: ViewKpiAppearance['decimalPlaceType'],
  // 移行前のデータに対応するため残しておく
  abbreviationType: ViewKpiAppearance['abbreviationType'],
): string | number {
  if (isNull(decimalPlaceType)) {
    return fixedDecimalByAbbreviationType(number_, abbreviationType)
  }

  switch (decimalPlaceType) {
    case 'automatic': {
      return roundValue(number_).toString()
    }
    case `noDecimalPlace`: {
      return number_.toString()
    }
    case `firstDecimalPlace`: {
      return number_.toFixed(0)
    }
    case `secondDecimalPlace`: {
      return number_.toFixed(1)
    }
    case `thirdDecimalPlace`: {
      return number_.toFixed(2)
    }
    case `fourthDecimalPlace`: {
      return number_.toFixed(3)
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const a: never = decimalPlaceType
      return number_.toString()
    }
  }
}

export function unitLabel(
  x: number | undefined,
  unit: ViewKpiAppearance['unitType'],
  abbreviationType: ViewKpiAppearance['abbreviationType'],
): string {
  if (x === undefined || x === 0) {
    // この条件では表示しない方がわかりやすい
    return ''
  }

  if (isNull(unit)) {
    return unitLabelByAbbreviationType(abbreviationType)
  }

  switch (unit) {
    case 'noUnit': {
      return ''
    }
    case 'unitThousand': {
      return t(`(千)`)
    }
    case 'unitTenThousand': {
      return t(`(万)`)
    }
    case 'unitMillion': {
      return t(`(百万)`)
    }
    case 'unitHandredMillion': {
      return t(`(億)`)
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const a: never = unit
      return ``
    }
  }
}

function unitLabelByAbbreviationType(abbreviationType: ViewKpiAppearance['abbreviationType']): string {
  // 移行前のデータに対応するため残しておく
  if (isNull(abbreviationType)) {
    return ''
  }

  switch (abbreviationType) {
    case 'noAbbreviation': {
      return ''
    }
    case 'abbreviateThousand': {
      // return Math.floor(x / 1000)
      return t(`(千)`)
    }
    case 'abbreviateTenThousand': {
      // return Math.floor(x / 1000 / 10)
      return t(`(万)`)
    }
    case 'abbreviateMillion': {
      // return Math.floor(x / 1000 / 1000)
      return t(`(百万)`)
    }
    case 'abbreviateHandredMillion': {
      // return Math.floor(x / 1000 / 1000 / 100)
      return t(`(億)`)
    }
    case 'automatic':
    case 'abbreviateDecimal':
    case 'abbreviateSecondDecimalPlace':
    case 'abbreviateThirdDecimalPlace':
    case 'abbreviateFourthDecimalPlace': {
      return ''
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const a: never = abbreviationType
      return ''
    }
  }
}

// 移行前のデータに対応するため残しておく
function calculateUnitWithAbbreviatedType(
  number_: number,
  abbreviationType: ViewKpiAppearance['abbreviationType'],
): number {
  if (isNull(abbreviationType)) {
    return number_
  }

  switch (abbreviationType) {
    case 'noAbbreviation': {
      return number_
    }
    case 'abbreviateThousand': {
      // return Math.floor(x / 1000)
      return number_ / 1000
    }
    case 'abbreviateTenThousand': {
      // return Math.floor(x / 1000 / 10)
      return number_ / 1000 / 10
    }
    case 'abbreviateMillion': {
      // return Math.floor(x / 1000 / 1000)
      return number_ / 1000 / 1000
    }
    case 'abbreviateHandredMillion': {
      // return Math.floor(x / 1000 / 1000 / 100)
      return number_ / 1000 / 1000 / 100
    }
    case 'automatic':
    case 'abbreviateDecimal':
    case 'abbreviateSecondDecimalPlace':
    case 'abbreviateThirdDecimalPlace':
    case 'abbreviateFourthDecimalPlace': {
      return number_
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const a: never = abbreviationType
      return number_
    }
  }
}

// 移行前のデータに対応するため残しておく
function fixedDecimalByAbbreviationType(
  number_: number,
  abbreviationType: ViewKpiAppearance['abbreviationType'],
): string | number {
  if (isNull(abbreviationType)) {
    return roundValue(number_).toString()
  }

  switch (abbreviationType) {
    case 'noAbbreviation': {
      return number_.toString()
    }
    case 'automatic': {
      return roundValue(number_).toString()
    }
    case 'abbreviateThousand':
    case 'abbreviateTenThousand':
    case 'abbreviateMillion':
    case 'abbreviateHandredMillion': {
      return roundValue(number_).toString()
    }
    case 'abbreviateDecimal': {
      return number_.toFixed(0)
    }
    case 'abbreviateSecondDecimalPlace': {
      return number_.toFixed(1)
    }
    case 'abbreviateThirdDecimalPlace': {
      return number_.toFixed(2)
    }
    case 'abbreviateFourthDecimalPlace': {
      return number_.toFixed(3)
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const a: never = abbreviationType
      return number_.toString()
    }
  }
}

export const ellipsisFilter = (string_: unknown, x: number) => {
  if (typeof string_ !== 'string') {
    return ''
  }

  if (string_.length <= x) {
    return string_
  }
  return string_.slice(0, x) + '...'
}

export const datetimeFormat = (string_?: string | null): string => {
  if (isNull(string_)) {
    return '-'
  }

  // NOTE: moment側でタイムゾーン変換をブラウザのタイムゾーンに合わせて変換してくれている？
  return t(`{{datetime, datetimestr}}`, { datetime: dayjs(string_) })
}

export const datetimeFormatJaYYYYMMDDHHmm = (string_?: string | null) => {
  if (isNull(string_)) {
    return '-'
  }

  // NOTE: moment側でタイムゾーン変換をブラウザのタイムゾーンに合わせて変換してくれている？
  return dayjs(string_).format('YYYY年MM月DD日 HH時mm分') // 文字列変換後にソートを行うようなユースケースに対応するため、H:mではなくHH:mmの形式にする
}
export function addModelIdProperty(
  modelAndNode: { node?: ViewConfigTreeNode; model?: CoreModel } | undefined,
  propertiesWithNode: Array<{ node: ViewConfigTreeNode; model: CoreModel; property: ModelProperty }>,
) {
  if (modelAndNode?.node === undefined || modelAndNode.model === undefined) {
    return propertiesWithNode
  }

  const modelIdProperty = {
    node: modelAndNode.node,
    model: modelAndNode.model,
    property: {
      ...modelAndNode.model,
      name: 'id',
      label: `${modelAndNode.model.label} ID`,
      type: 'string' as const,
      write: {
        sourcePropertyName: 'id',
      },
    },
    nodePathAsLabel: [modelAndNode.model.label],
  }

  return [modelIdProperty, ...propertiesWithNode]
}
