import { isNull } from './util'

function forceString(x: unknown): string | null | undefined {
  if (isNull(x)) {
    return x
  }

  if (typeof x === 'string') {
    return x
  }
  if (typeof x === 'number') {
    return x.toString()
  }
  return JSON.stringify(x)
}

export function countHankakuAndZenkaku(x: string) {
  // eslint-disable-next-line unicorn/prefer-spread
  const chars = x.split('')
  const hankakuChars = chars.filter((c) => isHankaku(c))
  const zenkakuChars = chars.filter((c) => !isHankaku(c))
  return {
    hankakuChars,
    zenkakuChars,
  }
}

function isHankaku(char: string) {
  return /^[\u0020-\u007E]*$/.test(char)
}

export function abbreviateText(x: string, n: number) {
  if (x.length < n) {
    return x
  }
  return `${x.slice(0, n)}...`
}

const snakeToCamel = (snakeCased: string) =>
  // Use a regular expression to find the underscores + the next letter
  snakeCased.replaceAll(/(_\w)/g, function (match) {
    // Convert to upper case and ignore the first char (=the underscore)
    return match.toUpperCase().slice(1)
  })
export const camelToSnake = (p: string) =>
  p.replaceAll(/([A-Z])/g, (s) => '_' + s.charAt(0).toLowerCase()).replace(/^_/, '')
export const camelToPascal = (p: string) => [p[0]?.toUpperCase() ?? '', p.slice(1)].join('')

export const snakeToPascal = (x: string) => camelToPascal(snakeToCamel(x))

//
// よくある「レコードの名前とパスと作成者名で検索。検索は半角スペースを使うとAND検索」のようなUIのためのナイーブな検索実装
//
const search = (query: string, targetStrings: string[]) =>
  query.split(/\s+/).every((q) => targetStrings.some((target) => target.includes(q)))

function doubleQuoteIfNotQuoted(x: string) {
  if (x.startsWith('"') && x.endsWith('"')) {
    return x
  }
  return `"${x}"`
}

// 途中から以下にまとめ始めた
export const stringUtil = {
  forceString,
  search,
  doubleQuoteIfNotQuoted,
}
