import { DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import { isSome } from '@salescore/buff-common'
import { Button, Input, message, Popconfirm, Space } from 'antd'
import type { FilterDropdownProps } from 'antd/es/table/interface'
import { t } from 'i18next'
import { type Dispatch, type SetStateAction, useRef } from 'react'

// eslint-disable-next-line @typescript-eslint/max-params
export function createDestroyColumn<T>(
  loading: boolean,
  setLoading: Dispatch<SetStateAction<boolean>>,
  onDestroy: (record: T) => Promise<void>,
  condition?: (x: T) => boolean,
  isSkipConfirm?: boolean,
) {
  return {
    title: '',
    key: 'destroy',
    width: 20,
    render(_text: unknown, record: T) {
      const confirm = async () => {
        try {
          setLoading(true)
          await onDestroy(record)
          void message.success(t(`削除しました`))
        } catch (error) {
          if (error instanceof Error) {
            void message.error(`${t(`削除に失敗しました。`)}${error.message}`)
          } else {
            void message.error(t(`削除に失敗しました。`))
          }
          throw error
        } finally {
          setLoading(false)
        }
      }

      if (condition !== undefined && !condition(record)) {
        return <></>
      }

      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (isSkipConfirm) {
        return <Button type="text" icon={<DeleteOutlined />} onClick={confirm} />
      }

      return (
        <Popconfirm
          title={t(`本当に削除しますか?`)}
          onConfirm={confirm}
          okText={t(`はい`)}
          cancelText={t(`いいえ`)}
          okButtonProps={{ loading }}
          onPopupClick={(e) => {
            e.stopPropagation()
          }}
        >
          <Button
            type="text"
            icon={<DeleteOutlined />}
            onClick={(e) => {
              e.stopPropagation()
            }}
          ></Button>
        </Popconfirm>
      )
    },
  }
}

export function getColumnSearchProps<T>(recordToValue: (record: T) => string) {
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const reference = useRef(null)

      // 正しい方法ではなさそうだが、これ以外でfocusできなかった
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const current: any = reference.current
        if (isSome(current)) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
          current.focus()
        }
      }, 200)

      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={reference}
            // placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              setSelectedKeys(e.target.value === undefined ? [] : [e.target.value])
              confirm({ closeDropdown: false })
            }}
            onPressEnter={(e) => {
              confirm({ closeDropdown: true })
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            {/* <Button
            type="primary"
            onClick={(e) => { confirm({ closeDropdown: true }) }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            検索
          </Button> */}
            <Button
              onClick={(e) => {
                if (clearFilters !== undefined) {
                  clearFilters()
                }
                confirm()
              }}
            >
              {t(`リセット`)}
            </Button>
          </Space>
        </div>
      )
    },
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: unknown, record: T) =>
      recordToValue(record)
        .toLowerCase() // ?.は不要なはずだが、実装ミスでこれがありえるようなので追加
        // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
        .includes(typeof value === 'string' ? value.toLowerCase() : (value as string)),
  }
}
