import type { MRecord } from '../util/record'
import { defineArrayProperties } from './array'
import { defineProperty } from './util'

let isInitialized = false

export function extendProperties() {
  if (isInitialized) {
    return
  }
  if (isAlreadyExtended()) {
    return
  }
  defineArrayProperties()
  isInitialized = true
}

// isInitializedのロジックだけで問題ないはずだったが、
// モノレポ移行後から動作しなくなってしまったので以下のロジックでもチェックする
function isAlreadyExtended(): boolean {
  try {
    defineProperty(
      Array.prototype,
      '_buffCommonExtendPropertiesCallledChecker',
      () => {
        // empty
      },
      'Array',
    )
    return false
  } catch {
    return true
  }
}

declare global {
  interface Array<T> {
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査): 58
     */
    groupBy: <Key extends string | number>(f: (x: T) => Key) => MRecord<Record<Key, T[]>>
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査): 32
     */
    groupByUniqueKey: <Key extends string | number>(f: (x: T) => Key) => Record<Key, T>
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査): 2
     */
    countBy: <Key extends string | number>(f: (x: T) => Key) => Record<Key, number>
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-03-08調査): 16
     */
    mySortBy: (f: (x: T) => unknown) => T[]
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査): 52
     */
    sortBy: (f: (x: T) => unknown) => T[]

    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査): 33
     */
    toObject: <K extends string | undefined, V>(
      f: (x: T, index: number) => [key: K, value: V],
    ) => Record<Exclude<K, undefined>, V>
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査): 2
     */
    toRecord: <V>(f: (x: T, index: number) => [key: string | undefined, value: V]) => MRecord<Record<string, V>>
    // toMap: () => Map<string, string> // TODO
    // toMap: () => Map<T[0], T[1]>
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査): 65
     */
    unique: () => T[]
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査): >100
     */
    uniqueBy: (f: (x: T) => unknown) => T[]
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査): 5
     */
    zip: <S>(ys: S[]) => Array<[T, S]>
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査):18
     */
    eachSlice: (x: number) => T[][]
    /**
     * @deprecated effectに移行してください
     *  使用箇所(2025-02-22調査):72
     */
    last: () => T | undefined
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査): > 100
     */
    first: () => T | undefined
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査): 72
     */
    sum: () => number
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査): > 100
     */
    compact: () => Array<Exclude<T, null | undefined>>
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査): 14
     */
    min: () => number | undefined // 配列の長さが0のときundefined
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査):38
     */
    max: () => number | undefined
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査):3
     */
    minBy: (f: (x: T) => number) => T | undefined
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査):3
     */
    maxBy: (f: (x: T) => number) => T | undefined
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査):2
     */
    sliceWhen: (f: (a: T, b: T) => boolean) => T[][]
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査):1
     */
    mapConsIncremental: () => T[][]
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査):20
     */
    partition: (f: (x: T) => boolean) => [trues: T[], falses: T[]]
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査):93
     */
    isPresent: () => boolean
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査):104
     */
    isBlank: () => boolean
    /**
     * @deprecated effectに移行してください
     *  使用箇所(2025-02-22調査):32
     */
    isEmpty: () => boolean
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査):15
     */
    isEqual: (xs: T[]) => boolean
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査):4
     */
    union: (xs: T[]) => T[]
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査):7
     */
    intersection: (xs: T[]) => T[]
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査):11
     */
    difference: (xs: T[]) => T[]
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査):3
     */
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
    intersectionBy: <S>(xs: T[], f: (x: T) => S) => T[]
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査):6
     */
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
    differenceBy: <S>(xs: T[], f: (x: T) => S) => T[]
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査):6
     */
    upsertBy: (x: T, f: (x: T) => string) => T[]
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査):12
     */
    move: (oldIndex: number, newIndex: number) => T[]
    /**
     * @deprecated effectに移行してください
     * 使用箇所(2025-02-22調査):4
     */
  }
}
