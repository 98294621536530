import { arrayMoveImmutable } from 'array-move'
import { useState } from 'react'

import { SortableList, type SortableListOptions } from './SortableList'

interface Item<T> {
  value: string
  label: string | JSX.Element
  key: string
  description?: string
  meta?: T
  options?: Array<{ value: string; label: string }>
  optionValues?: string[]
  path?: string
}

export function SortableListWrapper<T>({
  items,
  className,
  options,
  value,
  onChange,
  onOptionChange,
}: {
  items: Array<Item<T>>
  className?: string
  options?: SortableListOptions
  value?: string
  onChange: (items: Array<Item<T>>) => void | Promise<void>
  onOptionChange?: (argument: { item: Item<T>; value: string[] }) => void
}) {
  const [dragging, setDragging] = useState(false)
  const [loading, setLoading] = useState(false)

  // NOTE: ソート時のUXを良くするため、onChangeは非同期で行い、itemsやvalueの変更はソート直後に行われるようにした方が良い。
  const swapItem = async (oldIndex: number, newIndex: number) => {
    const newItems = arrayMoveImmutable(items, oldIndex, newIndex)
    try {
      setLoading(true)
      await onChange(newItems)
    } finally {
      setLoading(false)
    }
  }

  const removeItem = async (removedItem: Pick<Item<T>, 'value'>) => {
    const newItems = items.filter((item) => item.value !== removedItem.value)
    try {
      setLoading(true)
      await onChange(newItems)
    } finally {
      setLoading(false)
    }
  }

  const onSortEnd = async ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    setDragging(false)
    if (oldIndex === newIndex) {
      // 何もしない
    } else {
      await swapItem(oldIndex, newIndex)
    }
  }

  if (items.length === 0) {
    return <></>
  }

  return (
    <div style={{ overflow: 'auto' }}>
      <SortableList
        items={items}
        dragging={dragging}
        className={className}
        options={options}
        value={value}
        loading={loading}
        onDestroy={async (item) => {
          await removeItem(item)
        }}
        onEdit={options?.onEdit ?? undefined}
        onSortEnd={onSortEnd}
        onSortStart={() => {
          setDragging(true)
        }}
        onOptionChange={onOptionChange}
        axis="y"
        lockAxis="y"
        lockToContainerEdges={true}
        lockOffset="0%"
      />
    </div>
  )
}
