import { type RefObject, useEffect } from 'react'

import { logger } from '../misc/logtail'
import { useRafState } from './useRafState'

interface State {
  x: number
  y: number
}

function on<T extends Window | Document | HTMLElement | EventTarget>(
  object: T | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-function-type
  ...arguments_: Parameters<T['addEventListener']> | [string, Function | null, ...any]
): void {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (object?.addEventListener) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
    object.addEventListener(...(arguments_ as Parameters<HTMLElement['addEventListener']>))
  }
}

function off<T extends Window | Document | HTMLElement | EventTarget>(
  object: T | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-function-type
  ...arguments_: Parameters<T['removeEventListener']> | [string, Function | null, ...any]
): void {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (object?.removeEventListener) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
    object.removeEventListener(...(arguments_ as Parameters<HTMLElement['removeEventListener']>))
  }
}

export const useScroll = (reference: RefObject<HTMLElement>): State => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (process.env.NODE_ENV === 'development' && (typeof reference !== 'object' || reference.current === undefined)) {
    logger.debug('`useScroll` expects a single ref argument.')
  }

  const [state, setState] = useRafState<State>({
    x: 0,
    y: 0,
  })

  useEffect(() => {
    const handler = () => {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (reference.current) {
        setState({
          x: reference.current.scrollLeft,
          y: reference.current.scrollTop,
        })
      }
    }

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (reference.current) {
      on(reference.current, 'scroll', handler, {
        capture: false,
        passive: true,
      })
    }

    return () => {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (reference.current) {
        off(reference.current, 'scroll', handler)
      }
    }
  }, [reference])

  return state
}
