import { useState } from 'react'

/**
 * @deprecated loading状態はApollo Clientのフックから取得するようにする
 */
export const useBooleanState = (initialValue?: boolean) => {
  const [flag, setFlag] = useState(initialValue ?? false)

  return {
    isTrue: flag,
    isFalse: !flag,
    toggle() {
      setFlag((x) => !x)
    },
    setTrue() {
      setFlag(true)
    },
    setFalse() {
      setFlag(false)
    },
  }
}
