// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function defineProperty(target: any, name: string, f: unknown, objectName: string) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/strict-boolean-expressions
  if (target[name]) {
    throw new Error(
      `name: ${objectName}.${name} has already declared, but trying to declare again in "defineProperty".`,
    )
  }

  Object.defineProperty(target, name, {
    enumerable: false,
    configurable: false,
    writable: false,
    value: f,
  })
}
