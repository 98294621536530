import { useEffect, useRef, useState } from 'react'

export const useResizeObserver = (
  references: Array<React.MutableRefObject<null>>,
  callback: (entries: ResizeObserverEntry[]) => void,
) => {
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      callback(entries)
    })

    for (const reference of references) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (reference.current !== null) {
        resizeObserver.observe(reference.current)
      }
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [])
}

// 返り値のrefを使ったDOMのサイズを監視するhook
export const useResize = () => {
  // eslint-disable-next-line unicorn/no-useless-undefined
  const [rect, setRect] = useState<DOMRectReadOnly | undefined>(undefined)
  const reference = useRef(null)
  useResizeObserver([reference], (entries) => {
    const entry = entries[0]
    if (entry === undefined) {
      return
    }
    setRect(entry.contentRect)
  })

  return {
    ref: reference,
    rect,
  }
}
